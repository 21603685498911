




































import {
  Component, Prop, Emit, Vue, Watch,
} from 'vue-property-decorator';

class ItemSize {
  height: number;
  width: number;

  constructor(height: number, width: number) {
    this.height = height;
    this.width = width;
  }
}

@Component({
  name: 'muuri-item',
})
export default class MuuriItem extends Vue {
  @Prop({ type: String, default: 'item' }) private readonly id!: string;

  @Prop({ type: Boolean, default: true }) private readonly locked!: boolean;

  @Prop({ type: String }) private readonly link!: string;

  @Prop({ type: String }) private readonly title!: string;

  @Prop({
    type: Number,
    default: 50,
  }) private readonly width!: number;

  @Prop({
    type: Number,
    default: 50,
  }) private readonly height!: number;

  @Prop({
    type: Number,
    default: -1,
  }) private readonly gridHeight!: number;

  @Watch('height', { immediate: true })
  onHeightChanged(height: number) {
    this.determineSize(height, this.width);
  }

  @Watch('width', { immediate: true })
  onWidthChanged(width: number) {
    this.determineSize(this.height, width);
  }

  // Computed
  get maxSize(): boolean {
    const largestSize = this.getLargestSize();
    return (
      this.currentSize.height === largestSize.height
      && this.currentSize.width === largestSize.width
    );
  }

  get minSize(): boolean {
    const smallestSize = this.getSmallestSize();
    return (
      this.currentSize.height === smallestSize.height
      && this.currentSize.width === smallestSize.width
    );
  }

  get trueHeight(): number {
    if (this.gridHeight !== -1) {
      return this.gridHeight * (this.height * 0.01) - 8;
    }

    switch (this.height) {
      case 25:
        return 325;

      case 50:
        return 650;

      case 75:
        return 975;

      case 100:
        return 1300;

      default:
        return 325;
    }
  }

  get contentHeight(): number {
    return this.trueHeight - 30 - 14;
  }

  determineSize(height: number, width: number) {
    const closestSizeIndex = MuuriItem.itemSizes.findIndex(
      (size) => size.width === width && size.height === height,
    );

    this.currentSizeIndex = closestSizeIndex;
    this.currentSize = MuuriItem.itemSizes[this.currentSizeIndex];
  }

  private static itemSizes: ItemSize[] = [
    new ItemSize(25, 25),
    new ItemSize(50, 25),
    new ItemSize(50, 50),
    new ItemSize(75, 75),
    new ItemSize(100, 100),
  ];

  private currentSize!: ItemSize;
  private currentSizeIndex!: number;

  setCurrentSize(index: number) {
    this.currentSizeIndex = index;
    this.currentSize = MuuriItem.itemSizes[this.currentSizeIndex];
  }

  getSmallestSize() {
    return MuuriItem.itemSizes[0];
  }

  getLargestSize() {
    return MuuriItem.itemSizes[MuuriItem.itemSizes.length - 1];
  }

  openNewTab() {
    if (this.link) {
      window.open(this.link, '_blank');
    }
  }

  openNewWindow() {
    if (this.link) {
      window.open(`${this.link}?minimal=true`, 'window', 'width=1000, height=1000, toolbar=no, menubar=no, resizable=yes');
    }
  }

  @Emit('remove')
  remove() {
    console.log('Removing panel...');
  }

  @Emit('resize')
  increase() {
    console.log('Increasing size...');
    if (this.currentSizeIndex < MuuriItem.itemSizes.length - 1) {
      this.setCurrentSize(this.currentSizeIndex + 1);
    }

    return this.currentSize;
  }

  @Emit('resize')
  decrease() {
    console.log('Decreasing size...');
    if (this.currentSizeIndex > 0) {
      this.setCurrentSize(this.currentSizeIndex - 1);
    }

    return this.currentSize;
  }
}
