













































































































import {
  Component, Mixins, Vue, Watch,
} from 'vue-property-decorator';
import { capitalCase } from 'change-case';
import Muuri, { Item } from 'muuri';
import vuuri from 'vuuri';
import { Route } from 'vue-router';
import { Action, State } from 'vuex-class';

import MuuriGrid from '@/components/MuuriGrid.vue';
import MuuriItem from '@/components/MuuriItem.vue';

import Agencies from '@/views/agencies/Agencies.vue';
import Reports from '@/components/Reports.vue';
import QuickLauncher from '@/components/QuickLauncher.vue';
import Lenders from '@/views/lenders/Lenders.vue';
import LendersDetail from '@/views/lenders/LendersDetail.vue';
import Loans from '@/views/loans/Loans.vue';
import Recents from '@/views/Recents.vue';
import Billing from '@/views/billing/Billing.vue';
import Inbox from '@/views/messages/Inbox.vue';

import { Panel } from '@/store/dashboard';

import UserPermissions from '@/mixins/UserPermissions.vue';

interface MuuriPanel extends Panel {
  refId: string,
}

interface RoutePanel extends MuuriPanel {
  name: string,
  finalRoute: Route,
  id: any,
  dataKey: string,
}

@Component({
  name: 'panel-wall',
  components: {
    MuuriGrid,
    MuuriItem,
    Agencies,
    Billing,
    Reports,
    QuickLauncher,
    Lenders,
    Loans,
    Recents,
    Inbox,
    LendersDetail,
    vuuri,
  },
})
export default class PanelWall extends Mixins(UserPermissions) {
  @State((state) => state.dashboard.panels) panelConfig!: Panel[];
  @State((state) => state.config.roundedPanels) hasRoundedPanels!: boolean;

  @Action('savePanels') savePanels!: Function;
  @Action('setRoundedPanel') setRoundedPanel!: (rounded: boolean) => void;
  @Action('resetPanels') resetPanels!: () => void;

  private locked: boolean = true;

  private grid: Muuri = null;

  private resetDialog: boolean = false;

  private fontSizeRules = {
    maxWidth: 750,
    minWidth: 350,
    maxRatio: 1,
    minRatio: 0.5,
  };

  private gridHeight: number = -1;

  private oneColumn: boolean = false;

  @Watch('panelConfig')
  onPanelConfigChanged(panels: Panel[]) {
    const currentItems: Item[] = [].concat(this.grid.getItems());
    const newItems = panels.map((panel) => {
      const whitespaceRegex = / /g;
      const currentItem = currentItems.find((item) => item.getElement().children[0].children[0].attributes.getNamedItem('name').nodeValue.replace(whitespaceRegex, '') === panel.component);
      return currentItem;
    });

    this.$nextTick(() => {
      this.grid.sort(newItems);
    });
  }

  get panels(): RoutePanel[] {
    function determineFinalRoute(this: PanelWall, route: string, id?: string): Route {
      if (route === 'lenders-detail') {
        return this.$router.resolve({
          name: route,
          params: { id },
        }).route;
      }

      return route ? this.$router.resolve(route).route : undefined;
    }

    return this.panelConfig.map((config, index): RoutePanel => ({
      ...config,
      name: capitalCase(config.component),
      finalRoute: determineFinalRoute.call(this, config.route, this.user.lenderId),
      refId: config.component,
      id: config.component === 'lenders-detail' ? config.id : index,
      dataKey: `${capitalCase(config.component)}-${config.width}-${config.height}`,
    }));
  }

  async created() {
    window.addEventListener('resize', this.resizeHandler);
  }

  mounted() {
    this.resizeHandler();
    this.bindGrid((this.$refs.grid as any).muuri);
  }

  destroyed() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  bindGrid(grid: any) {
    this.grid = grid;
  }

  getItemWidth(item: Panel) {
    return `${item.width}%`;
  }

  getItemHeight(item: Panel) {
    const ratio = 0.01 * item.height;
    return `${ratio * 1000}px`;
  }

  resizeHandler(e?: UIEvent) {
    this.gridHeight = window.innerHeight - (8 * 2);

    this.oneColumn = this.$vuetify.breakpoint.lgAndDown;

    if (this.grid) {
      this.$nextTick(() => {
        this.grid.refreshItems().layout();
        setTimeout(() => {
          this.grid.refreshItems().layout();
        }, 1000);
      });
    }
  }

  save(name: string, size: { width: number, height: number }, refreshItems: boolean = false) {
    this.saveGrid(name, size);

    if (refreshItems) {
      this.$nextTick(() => {
        (this.$refs.grid as any).update();
      });
    }
  }

  saveGrid(name?: string, size?: { width: number, height: number }) {
    const panels: Panel[] = this.grid.getItems().map((item): Panel => {
      const itemElement = item.getElement().children[0];

      if (!itemElement) {
        throw new Error('No element for this panel.');
      }

      const routePanel = this.panels.find(
        (panel) => itemElement.children[0].id === (this.$refs[panel.refId] as { id: string }).id,
      );

      if (!routePanel) {
        throw new Error('Could not find element for panel while saving grid.');
      }

      return {
        width: name && name === routePanel.name ? size.width : routePanel.width,
        height: name && name === routePanel.name ? size.height : routePanel.height,
        component: routePanel.component,
        route: routePanel.route,
        id: routePanel.id,
      };
    });

    this.savePanels(panels);
  }

  toggleLock() {
    this.locked = !this.locked;
    if (this.locked) {
      (this.grid.getItems() as any[]).forEach((item) => {
        item._drag.destroy();
      });
    } else {
      (this.grid.getItems() as any[]).forEach((item) => {
        item._drag = new Muuri.ItemDrag(item);
      });
    }
  }

  toggleRounded() {
    this.setRoundedPanel(!this.hasRoundedPanels);
  }
}
