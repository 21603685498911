var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"panel-wall",style:({
  '--font-size': ("" + _vm.fontSize)
})},[_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.resetDialog),callback:function ($$v) {_vm.resetDialog=$$v},expression:"resetDialog"}},[_c('v-card',[_c('v-card-title',{class:("headline grey " + (_vm.$vuetify.theme.dark ? 'darken' : 'lighten') + "-2"),attrs:{"primary-title":""}},[_vm._v(" Confirm Reset ")]),_c('v-card-text',[_vm._v(" The following action would reset your panel layout and sizes to default. Proceed? ")]),_c('v-spacer'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.resetDialog = false}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":function($event){_vm.resetDialog = false; _vm.resetPanels();}}},[_vm._v(" Yes ")])],1)],1)],1),_c('div',{staticClass:"wall"},[_c('vuuri',{ref:'grid',attrs:{"id":"wall-grid","item-key":'dataKey',"get-item-width":_vm.getItemWidth,"get-item-height":_vm.getItemHeight,"drag-enabled":true},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('MuuriItem',{ref:item.refId,class:_vm.hasRoundedPanels ? 'rounded' : '',attrs:{"id":item.id.length > 1 ? 'item-sm-3' : 'item-sm-' + item.id,"link":item.finalRoute ? item.finalRoute.fullPath : null,"title":item.name,"locked":_vm.locked,"name":item.name},on:{"resize":function($event){return _vm.save(item.name, $event, true)}}},[(item.component === null)?_c('h1',[_vm._v(_vm._s(item.title))]):_c(item.component,{tag:"component",staticClass:"panel-component",attrs:{"id":item.id,"size":'sm',"view":'panel-wall',"fontSizeRules":_vm.fontSizeRules,"one-column":_vm.oneColumn}})],1)]}}]),model:{value:(_vm.panels),callback:function ($$v) {_vm.panels=$$v},expression:"panels"}})],1),_c('div',{staticClass:"options"},[_c('div',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('button',[_c('font-awesome-icon',_vm._g({attrs:{"icon":_vm.locked ? 'unlock' : 'lock'},on:{"click":_vm.toggleLock}},on))],1)]}}])},[_c('span',[_vm._v("Unlock Panel Size")])])],1),_c('div',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('button',[_c('font-awesome-icon',_vm._g({attrs:{"icon":_vm.hasRoundedPanels ? 'square-full' : 'circle'},on:{"click":_vm.toggleRounded}},on))],1)]}}])},[_c('span',[_vm._v("Toggle Corner Style")])])],1),_c('div',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('button',[_c('font-awesome-icon',_vm._g({attrs:{"icon":'undo'},on:{"click":function($event){_vm.resetDialog = true}}},on))],1)]}}])},[_c('span',[_vm._v("Reset Dashboard")])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }