






import Muuri, { GridOptions } from 'muuri';
import {
  Component, Prop, Emit, Vue, Watch,
} from 'vue-property-decorator';

@Component({
  name: 'muuri-grid',
})
export default class MuuriGrid extends Vue {
  private grid: Muuri = null;

  @Prop({
    type: String,
    default: 'grid',
  }) private readonly id!: string;

  @Prop({
    type: Object,
    default: () => ({
      items: '.item',
      dragEnabled: true,
      layout: {
        fillGaps: true,
        horizontal: false,
        alignRight: false,
        alignBottom: false,
      },
    }),
  }) private readonly options!: GridOptions;

  @Watch('options')
  onOptionsChanged(oldOptions: any, newOptions: any) {
    Object.keys(newOptions).forEach((key) => {
      const localGrid = this.grid as any;
      if (localGrid._settings[key] != null) {
        localGrid._settings = newOptions[key];
      }
    });
  }

  mounted() {
    this.createGrid();
    this.registerEvents();
  }

  refreshLayout() {
    console.log('Child refreshed...');
    this.grid.refreshItems().layout();
  }

  createGrid() {
    this.options.layout = {
      fillGaps: true,
      horizontal: false,
      alignRight: false,
      alignBottom: false,
      rounding: false,
    };

    this.grid = new Muuri(`#${this.id}`, this.options);
    this.$emit('gridCreated', this.grid);
  }

  registerEvents() {
    this.grid.on('synchronize', () => {
      this.$emit('synchronize');
    });
    this.grid.on('layoutStart', (items) => {
      this.$emit('layoutStart', items);
    });
    this.grid.on('layoutEnd', (items) => {
      this.$emit('layoutEnd', items);
    });
    this.grid.on('add', (items) => {
      this.$emit('add', items);
    });
    this.grid.on('remove', (indices) => {
      this.$emit('remove', indices);
    });
    this.grid.on('showStart', (items) => {
      this.$emit('showStart', items);
    });
    this.grid.on('showEnd', (items) => {
      this.$emit('showEnd', items);
    });
    this.grid.on('hideStart', (items) => {
      this.$emit('hideStart', items);
    });
    this.grid.on('hideEnd', (items) => {
      this.$emit('hideEnd', items);
    });
    this.grid.on('filter', (shownItems, hiddenItems) => {
      this.$emit('filter', shownItems, hiddenItems);
    });
    this.grid.on('sort', (currentOrder, previousOrder) => {
      this.$emit('sort', currentOrder, previousOrder);
    });
    this.grid.on('move', (data) => {
      this.$emit('move', data);
    });
    this.grid.on('send', (data) => {
      this.$emit('send', data);
    });
    this.grid.on('beforeSend', (data) => {
      this.$emit('beforeSend', data);
    });
    this.grid.on('receive', (data) => {
      this.$emit('receive', data);
    });
    this.grid.on('beforeReceive', (data) => {
      this.$emit('beforeReceive', data);
    });
    this.grid.on('dragInit', (item, event) => {
      this.$emit('dragInit', item, event);
    });
    this.grid.on('dragStart', (item, event) => {
      this.$emit('dragStart', item, event);
    });
    this.grid.on('dragMove', (item, event) => {
      this.$emit('dragMove', item, event);
    });
    this.grid.on('dragScroll', (item, event) => {
      this.$emit('dragScroll', item, event);
    });
    this.grid.on('dragEnd', (item, event) => {
      this.$emit('dragEnd', item, event);
    });
    this.grid.on('dragReleaseStart', (item) => {
      this.$emit('dragReleaseStart', item);
    });
    this.grid.on('dragReleaseEnd', (item) => {
      this.$emit('dragReleaseEnd', item);
    });
    this.grid.on('destroy', () => {
      this.$emit('destroy');
    });
  }
}
