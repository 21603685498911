


















import {
  Component, Prop, Vue,
} from 'vue-property-decorator';

import _ from 'lodash';

import AgenciesList from '@/views/agencies/AgenciesList.vue';
import AgenciesDetail from '@/views/agencies/AgenciesDetail.vue';

@Component({
  name: 'recents',
})
export default class Recents extends Vue {
  recentlyVisited: any[] = [];

  created() {
    if (localStorage.recentlyVisited) {
      this.recentlyVisited = JSON.parse(localStorage.recentlyVisited).reverse();
    }

    this.recentlyVisited = this.recentlyVisited.map((recentlyVisitedEntry) => {
      let entity;

      return Object.assign(recentlyVisitedEntry, { entity });
    });
  }

  buildSummary(entity: any) {
    const { type, timestamp, id } = entity;
    let summary;

    switch (type) {
      case 'agency':
        summary = `Agency ${id}`;
        break;

      case 'lender':
        summary = `Lender ${id}`;
        break;

      case 'loan':
        summary = `Loan ${id}`;
        break;

      default:
        break;
    }

    return summary;
  }

  openLink(entity: any) {
    const { type, id } = entity;
    let link;

    switch (type) {
      case 'agency':
        link = `/agencies/${id}`;
        break;

      case 'lender':
        link = `/lenders/${id}`;
        break;

      case 'loan':
        link = `/loans/${id}`;
        break;

      default:
        break;
    }

    if (link) {
      window.open(link, '_blank');
    }
  }
}
