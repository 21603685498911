























import {
  Component, Prop, Vue,
} from 'vue-property-decorator';

import { IServiceQueryDefinition } from '@/services/api/models/IServiceQuery';

import Message from '@/entities/messages/Message';

export interface MessageAttachment {
  type: 'file' | 'query',
  name: string,
  url?: string,
  query?: IServiceQueryDefinition,
}

@Component({
  name: 'news-center',
})
export default class NewsCenter extends Vue {
  @Prop({
    type: Array,
    default: (): Message[] => [],
  }) private readonly value!: Message[];
}
