























import { Component, Vue } from 'vue-property-decorator';

import UserService from '@/services/users';
import { MessageType } from '@/services/api/models/IServiceMessage';

import PanelWall from '@/components/PanelWall.vue';

import NewsCenter from '@/views/messages/NewsCenter.vue';
import UserNotes from '@/views/users/UserNotes.vue';

import User from '@/entities/User';
import Message from '@/entities/messages/Message';

import { Action, State } from 'vuex-class';

@Component({
  name: 'dashboard',
  components: {
    PanelWall,
    NewsCenter,
    UserNotes,
  },
})
export default class Dashboard extends Vue {
  @State((state) => state.user.user) user!: User;

  @Action('fetchUser') fetchUser!: () => void;
  @Action('updateNotes') updateNotes!: (notes: string) => void;

  private news: Message[] = [];

  private userService: UserService = new UserService();

  async created() {
    this.getNews();
    this.fetchUser();
  }

  getNews() {
    return this.userService.getUserInbox()
      .then((messages) => {
        this.news = messages.filter((message) => message.type === MessageType.news);
      });
  }

  saveNotes(notes: string) {
    return this.updateNotes(notes);
  }
}
