





















import { Component, Prop, Vue } from 'vue-property-decorator';

interface QuickLaunchTarget {
  name: string,
  link: string,
}

const defaultTargets = [
  {
    name: 'Billing',
    link: '/billing',
  },
  {
    name: 'Quick Entry',
    link: '/quick-entry',
  },
  {
    name: 'Import Utilities',
    link: '/import',
  },
  {
    name: ' Admin Settings',
    link: '/admin-settings',
  },
  {
    name: 'History',
    link: '/history',
  },
];

@Component({
  name: 'quick-launcher',
})
export default class QuickLauncher extends Vue {
  @Prop({
    default: () => defaultTargets,
  }) private readonly targets!: QuickLaunchTarget[];

  @Prop({ type: Boolean, default: false }) private readonly oneColumn!: boolean;

  openNewTab(target: QuickLaunchTarget) {
    window.open(target.link, '_blank');
  }

  openNewWindow(target: QuickLaunchTarget) {
    window.open(`${target.link}?minimal=true`, `${target.name}`, 'width=1000, height=1000, toolbar=no, menubar=no, resizable=yes');
  }
}
