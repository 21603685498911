














import {
  Component, Prop, Vue, Emit,
} from 'vue-property-decorator';

@Component({
  name: 'user-notes',
})
export default class UserNotes extends Vue {
  @Prop({
    type: String,
    default: '',
  }) private readonly value!: String;

  @Emit('input')
  updateNotes(e: string) {}
}
